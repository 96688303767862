/* eslint-disable */

import 'mdn-polyfills/Element.prototype.matches'
import 'mdn-polyfills/Node.prototype.remove'
import 'whatwg-fetch'
import browserUpdate from 'browser-update/update.npm.js'

browserUpdate({
  api: 2020.03,
  no_permanent_hide: true, // Don't allow for the message to be hidden
  nostatistics: true, // Don't report any data to browser-update.org
  reminder: 0, // Always show (never disappear)
  reminderClosed: 0, // Always show (never disappear)
  required: {
    c: 45, // Google Chrome
    e: 13, // Microsoft Edge
    f: 38, // Mozilla Firefox
    i: 11, // Microsoft Internet Explorer
    ios: 11, // Apple iOS Browser
    o: 30, // Opera
    s: 11 // Apple Safari
  }
});

(function () {
  if (typeof window.Event != 'function') {
    function Event (eventName) {
      const event = document.createEvent('Event')
      event.initEvent(eventName, true, true)
      return event
    }

    window.Event = Event
  }
})()
